<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
    size="sm"
  >
    <b-row>
      <form-select
        label="Service Type"
        v-model="selected.service_type_data"
        v-validate="`required`"
        data-vv-as=""
        :url="`/api/statusData/service_rate_datas`"
        :trackLable="`name_th`"
        :col="12"
      />
      <form-input-currency
        label="Credit Rate"
        v-model="form.amount"
        v-validate="`required`"
        data-vv-as="Credit Rate"
        :col="12"
      />
    </b-row>
  </form-modal>
</template>
<script>
export default {
  computed: {
    _title() {
      return this.form.id ? `Update Service Rate Data` : "Create Service Rate"
    },
  },
  data() {
    return {
      selected: {
        service_type_data: "",
      },
      form: {
        id: "",
        service_type_data_id: "",
        amount: 0,
      },
    }
  },
  methods: {
    show(data = "") {
      this.clearForm()
      if (data) {
        this.form = data
        this.selected.service_type_data = data.service_type_data
      }
      this.switch("show")
    },
    async handleSubmit() {
      try {
        const validatetor = await this.$validator.validateAll()
        if (!validatetor) {
          this.alertValidate()
          return ""
        }
        const form = this.cp(this.form)
        form.service_type_data_id = this.selected.service_type_data.id
        const resp = await this.api.createOrUpdate(
          `/api/serviceRate`,
          form,
          "Service Rate Data"
        )
        this.switch("hide")
        this.$emit("reload", "")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
